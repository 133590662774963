<template>
<v-app>
    <v-main style="padding: 0; margin-top: -15vh !important; padding: 30px">
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
              <v-col xl="12" lg="12" md="12" sm="12" xs="12" align="center" justify="center"><h2 class="mb-5">PAYMENT SUCCESSFUL</h2></v-col>
              <v-col align="center" justify="center">
                <v-img src="/img/payment_success.jpg" alt="Successful Payment" contain max-height="40vh" />
              </v-col>
            </v-row>
        </v-container>
    </v-main>
</v-app>
</template>

<script>

export default {
    name: 'SuccessPayment',
    async mounted() {
      if (location.search.trim() !== '') {
        console.log('has params')
        let query = decodeURIComponent(location.search.substring(1))
        let queries = query.split('&')
          .map(q => {
              let key = q.substring(0, q.indexOf('='));
              let value = q.substring(q.indexOf('=') + 1);
              return {
                  key,
                  value,
              }
          });
        for (var q in queries) {
          if(queries[q].key == 'redirect') {
            window.parent.postMessage(JSON.stringify({url: `${queries[q].value}`}), '*')
            break;
          }
        }
      }
    },
}
</script>
